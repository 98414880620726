// extracted by mini-css-extract-plugin
export var fadeIn = "d_x";
export var featuresSection = "d_C";
export var featuresSectionImage = "d_D";
export var headSection = "d_v";
export var headSectionImage = "d_y";
export var headSectionLogo = "d_w";
export var icons = "d_G";
export var relatedProductsSection = "d_F";
export var textSection = "d_z";
export var textSectionVideo = "d_B";